import React, { useState, useContext, useRef, useEffect } from "react";
import { MessageBox } from "../../../components";

import FilterQuestions from "./FilterQuestions";
import PersonalizationBox from "./PersonalizationBox";
import LeftQuestionBox from "./LeftQuestionBox";
import RightQuestionBox from "./RightQuestionBox";

import AvatarContext from "../../../components/AvatarContext";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import annyang from "annyang";
import { fetchContent } from "../../../content/FetchContent";
import { getCookie } from "utils/cookie";

export const CenterVideoLayout = () => {
  
  const idleRef = useRef(null);
  const greetingRef = useRef(null);

  const personalized = useSelector((state) => state.auth.personalized);
  const user = useSelector((state) => state.auth.user);
  const [question, setQuestion] = useState("");
  const [showPauseButton, setShowPauseButton] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const {
    greetingVideo,
    setGreetingVideo,
    personalizedQuest,
    setRequestLoader,
    listening,
    loader,
    playing,
    setLoader,
    playVideo,
    brand,
  } = useContext(AvatarContext);
  const [greetingVideoSilent, setGreetingVideoSilent] = useState(true);
  const [storeGreetingVideo, setStoreGreetingVideo] = useState(null);
  const [selectCategoryType, setSelectCategoryType] = React.useState("");
  const [content, setContent] = useState({
    default: [],
    filterQuestion: {},
    filters: [],
    ISIModal: "",
  });
  const questionBox = content?.options?.find(
    (item) => item.type === "questionBox"
  );
  const personalizationBox = content?.options?.find(
    (item) => item.type === "personalizationBox"
  );



  const startAnnyang = () => {
    annyang.abort();
    if (annyang) {
      // Define a command

      const commands = {
        "*text": (text) => {
          setQuestion(text);
          setRequestLoader(true);
        },
      };

      // Add commands to annyang
      annyang.addCommands(commands);

      // Start annyang
      annyang.start();
    }
  };

  useEffect(() => {
    if (user != null) {
      const fetchScript = async () => {
        const { success, content } = await fetchContent(
          "homeScreen",
          user?.brand
        );

        if (success) setContent(content);
      };
      fetchScript();
    }
  }, [user]);

  useEffect(() => {
    if (greetingVideo) {
      if (personalized) {
        greetingRef.current.muted = false;
      }

      greetingRef.current.src = greetingVideo;
      setStoreGreetingVideo(greetingVideo);
      const checkVideoLoaded = () => {
        try {
          if (greetingRef?.current?.readyState === 4) {
            setGreetingVideoSilent(false);
            if (playing) greetingRef.current.play();
          } else {
            setTimeout(checkVideoLoaded, 100);
          }
        } catch (error) {
          throw error;
        }
      };
      checkVideoLoaded();
    } else {
      idleRef.current.src = "/videos/silence_conv.mp4";
    }
  }, [greetingVideo]);

  const togglePlay = () => {
    if (greetingRef.current) {
      greetingRef.current.play();
      playVideo(true);
    }
  };
  const togglePause = () => {
    if (greetingRef.current) {
      greetingRef.current.pause();
      playVideo(false);
    }
  };
  const playGreetingVideoAgain = () => {
    setGreetingVideo(storeGreetingVideo);
    playVideo(true);
    setShowRepeat(false);
  };
   return (
    <div className={`w-full relative`}>
      {loader && (
        <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
          <Loader />
        </div>
       )}
      <div className="flex flex-col items-center justify-center pt-2 relative md:overflow-hidden">
        <div className="">
          <PersonalizationBox
            personalizedQuest={personalizedQuest}
            user={user}
            personalizationOption={personalizationBox}
          />
        </div>
        
        <div className={`flex flex-wrap w-full ${personalizedQuest ? "transition-width duration-700 ease-in-out" : ""}`}>
          <div className="md:w-1/3 w-full md:block hidden">
            <LeftQuestionBox
              selectCategoryType={selectCategoryType}
              questions={questionBox?.leftSection}
            />
          </div>
          
          <div className="w-full md:w-1/3 flex flex-col items-center relative transition-width duration-100 ease-in-out mx-auto">
            <div
              className="w-full px-0 md:px-4 mb-2 overflow-hidden"
              onMouseEnter={() => setShowPauseButton(true)}
              onMouseLeave={() => setShowPauseButton(false)}
            >
              <p className="text-sm text-gray-800 font-semibold absolute top-32">* Not an actual person</p>
              <video
                ref={greetingRef}
                className={`w-full object-cover rounded-t-md md:rounded-md ${personalizedQuest ? "mt-[-15%]" : ""}`}
                style={{
                  display: greetingVideoSilent === false ? 'block' : 'none',
                  height: 'calc(100% + 10%)',
                }}
                onError={(e) => {}}
                onCanPlay={() => setLoader(false)}
                onClick={() => (greetingRef.current.muted = false)}
                onEnded={(e) => {
                  setGreetingVideo(null);
                  if (listening) startAnnyang();
                  setTimeout(() => {
                    setGreetingVideoSilent(true);
                    setShowRepeat(true);
                  }, 50);
                }}
              />
              <video
                ref={idleRef}
                autoPlay
                muted
                onCanPlay={() => setLoader(false)}
                className={`w-full object-cover rounded-t-md md:rounded-md ${personalizedQuest ? "mt-[-15%]" : ""}`}
                loop
                style={{
                  display: greetingVideoSilent ? 'block' : 'none',
                  height: 'calc(100% + 10%)',
                }}
                onCanPlayThrough={(e) => {}}
                onError={(e) => {}}
              />
              {showRepeat && (
                <button
                  onClick={() => playGreetingVideoAgain()}
                  style={repeatButtonStyle}
                  className="animate-fade-in-1s"
                >
                  Click to Replay
                </button>
              )}
              {!greetingVideoSilent ? (
                !playing ? (
                  <button onClick={() => togglePlay()} style={videoButtonStyle}>
                    Click to start
                  </button>
                ) : (
                  showPauseButton && (
                    <button onClick={() => togglePause()} style={videoButtonStyle}>
                      Click to stop
                    </button>
                  )
                )
              ) : null}
               <div className={`absolute flex items-center justify-center  
                ${personalizedQuest ? "md:bottom-48 bottom-0 bg-red-200" : "md:bottom-48 bottom-0"}  left-0 right-0 z-10`}>
            <div className="w-full md:max-w-[80%] flex items-center">
              <FilterQuestions
                selectCategoryType={selectCategoryType}
                setSelectCategoryType={setSelectCategoryType}
                filterOptions={["-", ...content?.filters]}
              />
                <MessageBox question={question} setQuestion={setQuestion} />
            
            </div>
          </div>
            </div>
            
           
           </div>
           <div className="md:hidden block w-full">
            <LeftQuestionBox
              selectCategoryType={selectCategoryType}
              questions={questionBox?.leftSection}
            />
          </div>
          
          <div className="md:w-1/3 w-full mx-auto">
            <RightQuestionBox
              selectCategoryType={selectCategoryType}
              questions={questionBox?.rightSection}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const repeatButtonStyle = {
  position: "absolute",

  top: "60%",
  left: "40%",
  padding: "5px 10px",
  cursor: "pointer",
  border: "none",
  background: "rgba(255, 255, 255, 0.7)",
  borderRadius: "5px",
  fontSize: "small",
};

const videoButtonStyle = {
  position: "absolute",

  top: "60%",
  left: "40%",
  padding: "5px 10px",
  cursor: "pointer",
  border: "none",
  background: "rgba(255, 255, 255, 0.7)",
  borderRadius: "5px",
  fontSize: "small",
};

export default CenterVideoLayout;
