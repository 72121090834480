import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import VideoPlayer from "../components/VideoPlayer";

const WelcomeContent = ({ content, videoControls }) => {
  return (
    <div className="animate-fade-in-1s flex flex-col justify-between items-center text-center pt-5 pb-10">
      <div className="flex-1 w-full h-full flex flex-col justify-center items-center">
        <div>
          <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 md:text-2xl">
            {content.title}
          </h5>
          <p className="font-normal text-gray-600 dark:text-gray-400">
            {content.subtitle}
          </p>
        </div>

        <VideoPlayer
          videoRef={videoControls.videoRef}
          content={content}
          togglePlay={videoControls.togglePlay}
          togglePause={videoControls.togglePause}
          muteButton={videoControls.muteButton}
          showPauseButton={videoControls.showPauseButton}
          setShowPauseButton={videoControls.setShowPauseButton} // Pass the setter function
        />
        {/* NOTE: This is a temporary fix to bypass the invite acceptance step */}
        {/* <p className="font-normal text-sm text-gray-700 dark:text-gray-400 italic max-w-[45em] mx-auto overflow-hidden text-center px-2.5 my-5">
          {content.description}
        </p> */}

        <Link
          className="inline-flex items-center w-full justify-center mt-5"
          to={`/configuration`}
        >
          <button className="flex items-center gap-4 bg-primary text-white font-bold px-4 py-2 rounded justify-center text-center">
            {content.button} <FaArrowRight />
          </button>
        </Link>
      </div>

      <div className="self-center">
        <p>Powered by Jawaab™</p>
        <span className="text-xs">A ValueDo HCP Engagement Platform</span>
      </div>
    </div>
  );
};

export default WelcomeContent;
