import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, updateUser } from "../../../utils/handleRequest";
import { getContent } from "../../../utils/getContent";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const useUserVerification = (inviteId) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState(null);
  const loggedinUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    const verifyUser = async () => {
      if (inviteId === null && loggedinUser != null) return;
      await signIN(inviteId);
    };

    verifyUser();
  }, []);

  const signIN = async () => {
    try {
      const { success, data } = await getUserInfo();
      if (success) {
        dispatch({
          type: "SET_USER",
          payload: data,
        });
        if (data.inviteAccepted) {
          toast.error(
            "You have already accepted the invitation. Please set a new password or sign in"
          );
          navigate("/signin");
          return;
        }
        // NOTE: This is a temporary fix to bypass the invite acceptance step
        // const inviteAccepted = await updateUser({
        //   inviteAccepted: true,
        //   termsOfUseAccepted: true,
        // });
        if (true) {
          const { brand } = data;
          const screenContent = await getContent(brand, "welcome");
          setContent(screenContent);
          toast.success("Email verified successfully!");
        }
      } else {
        throw new Error("Verification failed");
      }
    } catch (error) {
      toast.error("Invitation link is invalid");
      navigate("/signin");
    } finally {
      setLoader(false);
    }
  };

  return { loader, content };
};

export default useUserVerification;
