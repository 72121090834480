import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { MdHealthAndSafety } from "react-icons/md";
import { clearCookie } from "utils/cookie";
import { fetchContent } from "content/FetchContent";

const ISIModal = ({ setShowISIModal }) => {
  const user = useSelector((state) => state.auth.user);
  const [ISImodalContent, setISIModalContent] = useState("");

  useEffect(() => {
    if (!user) return;
    const fetchISIModal = async () => {
      const { success, content } = await fetchContent("ISIModal", user?.brand);
      if (success) setISIModalContent(content?.content);
    };
    fetchISIModal();
  }, [user]);

  return (
    // <Modal
    //   centered
    //   open={true}
    //   width={1200}
    //   closable={true}
    //   onCancel={() => {
    //     setShowISIModal(false);
    //     clearCookie("firstTime");
    //   }}
    //   cancelButtonProps={{ style: { display: "none" } }}
    //   className="p-6"
    //   footer={null}
    // >
    //   <div className="p-6 flex flex-col items-center">
    //     <div className="w-14 h-14 bg-[#004D74]/20 rounded-full flex items-center justify-center">
    //       <MdHealthAndSafety className="w-8 h-8 text-[#004D74]" />
    //     </div>
    //     <h2 className="text-lg sm:text-xl  text-[#004D74] font-semibold mt-4">
    //       IMPORTANT SAFETY INFORMATION
    //     </h2>
    //     <p className="text-sm text-gray-500 text-center">
    //       Please read the following important safety information for{" "}
    //       {user?.brand}.
    //     </p>
    //   </div>
      <div className="h-full overflow-y-auto px-6 mt-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-3">
            <div>
              <h2 class="text-lg sm:text-xl  text-[#004D74] font-bold">INDICATIONS</h2>
              <p>
                LYNPARZA is a poly (ADP-ribose) polymerase (PARP)
                {" "} <span class="whitespace-nowrap">inhibitor indicated:</span> {" "}
              </p>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  First-Line Maintenance <em>BRCA</em>m Advanced
                  {" "} <span class="whitespace-nowrap">Ovarian Cancer</span> {" "}
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  For the maintenance treatment of adult patients with
                  deleterious or suspected deleterious germline or somatic
                  {" "} <span class="whitespace-nowrap">
                    <em>BRCA</em>-mutated
                  </span> {" "}
                  {" "} <span class="whitespace-nowrap">
                    (g<em>BRCA</em>m or s<em>BRCA</em>m)
                  </span> {" "} {" "}
                  advanced epithelial ovarian, fallopian tube, or primary
                  peritoneal cancer who are in complete or partial response to
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "}
                  {" "} <span class="whitespace-nowrap">platinum-based</span> {" "}{" "}
                  chemotherapy. Select patients for therapy based on an
                  {" "} <span class="whitespace-nowrap">FDA-approved</span> {" "} companion
                  diagnostic
                  {" "} <span class="whitespace-nowrap">for LYNPARZA.</span> {" "}
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  First-Line Maintenance HRD-Positive Advanced Ovarian Cancer in
                  Combination with Bevacizumab
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  In combination with bevacizumab for the maintenance treatment
                  of adult patients with advanced epithelial ovarian, fallopian
                  tube or primary peritoneal cancer who are in complete or
                  partial response to
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "}
                  {" "} <span class="whitespace-nowrap">platinum-based</span> {" "}{" "}
                  chemotherapy and whose cancer is associated with homologous
                  recombination deficiency
                  {" "} <span class="whitespace-nowrap">(HRD)-positive</span> {" "} status
                  defined by either:
                </p>
                <ul class="list-disc ml-5">
                  <li>
                    a deleterious or suspected deleterious <em>BRCA</em>{" "}
                    mutation, and/or
                  </li>
                  <li>genomic instability</li>
                </ul>
                <p>
                  Select patients for therapy based on an
                  {" "} <span class="whitespace-nowrap">FDA-approved</span> {" "} companion
                  diagnostic for LYNPARZA.
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  Maintenance <em>BRCA</em>-mutated Recurrent Ovarian Cancer
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  For the maintenance treatment of adult patients with
                  deleterious or suspected deleterious germline or somatic{" "}
                  <em>BRCA</em>-mutated (g<em>BRCA</em>m or s<em>BRCA</em>m)
                  recurrent epithelial ovarian, fallopian tube, or primary
                  peritoneal cancer, who are in complete or partial response to
                  platinum-based chemotherapy. Select patients for therapy based
                  on an FDA-approved companion diagnostic for LYNPARZA.
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  Adjuvant Treatment of g<em>BRCA</em>m, HER2-Negative,
                  {" "} <span class="whitespace-nowrap">High-Risk</span> {" "} Early Breast
                  Cancer
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  For the adjuvant treatment of adult patients with deleterious
                  or suspected deleterious
                  {" "} <span class="whitespace-nowrap">
                    g<em>BRCA</em>m,
                  </span> {" "}{" "}
                  human epidermal growth factor receptor 2 (HER2)-negative,
                  high-risk early breast cancer who have been treated with
                  neoadjuvant or adjuvant chemotherapy. Select patients for
                  therapy based on an
                  {" "} <span class="whitespace-nowrap">FDA-approved</span> {" "} companion
                  diagnostic for LYNPARZA.
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  g<em>BRCA</em>m,{" "}
                  {" "} <span class="whitespace-nowrap">HER2-Negative</span> {" "}
                  Metastatic Breast Cancer
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  For the treatment of adult patients with deleterious or
                  suspected deleterious g<em>BRCA</em>m, human epidermal growth
                  factor receptor 2
                  {" "} <span class="whitespace-nowrap">(HER2)-negative</span> {" "}{" "}
                  metastatic breast cancer who have been treated with
                  chemotherapy in the neoadjuvant, adjuvant, or metastatic
                  setting. Patients with hormone receptor{" "}
                  {" "} <span class="whitespace-nowrap">(HR)-positive</span> {" "} breast
                  cancer should have been treated with a prior endocrine therapy
                  or be considered inappropriate for endocrine therapy. Select
                  patients for therapy based on an
                  {" "} <span class="whitespace-nowrap">FDA-approved</span> {" "} companion
                  diagnostic{" "}
                  {" "} <span class="whitespace-nowrap">for LYNPARZA.</span> {" "}
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  First-Line Maintenance g<em>BRCA</em>m Metastatic
                  {" "} <span class="whitespace-nowrap">Pancreatic Cancer</span> {" "}
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  For the maintenance treatment of adult patients with
                  deleterious or suspected deleterious g<em>BRCA</em>m
                  metastatic pancreatic adenocarcinoma whose disease has not
                  progressed on at least 16 weeks of a{" "}
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "}
                  {" "} <span class="whitespace-nowrap">platinum-based</span> {" "}{" "}
                  chemotherapy regimen. Select patients for therapy based on an
                  {" "} <span class="whitespace-nowrap">FDA-approved</span> {" "} companion
                  diagnostic
                  {" "} <span class="whitespace-nowrap">for LYNPARZA.</span> {" "}
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                  HRR Gene-mutated Metastatic
                  {" "} <span class="whitespace-nowrap">
                    Castration-Resistant
                  </span> {" "}{" "}
                  Prostate Cancer
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  For the treatment of adult patients with deleterious or
                  suspected deleterious germline or somatic homologous
                  recombination repair (HRR)
                  {" "} <span class="whitespace-nowrap">gene-mutated</span> {" "} metastatic
                  {" "} <span class="whitespace-nowrap">
                    castration-resistant
                  </span> {" "}{" "}
                  prostate cancer (mCRPC) who have progressed following prior
                  treatment with enzalutamide or abiraterone. Select patients
                  for therapy based on an
                  {" "} <span class="whitespace-nowrap">FDA-approved</span> {" "} companion
                  diagnostic for LYNPARZA.
                </p>
              </div>
              <div>
                <h3 class="text-lg sm:text-xl text-wrap text-[#004D74] font-semibold">
                  <em>BRCA</em>m Metastatic Castration-Resistant
                  {" "} <span class="whitespace-nowrap">
                    Prostate Cancer in Combination with
                  </span> {" "}
                 {" "} Abiraterone and Prednisone or Prednisolone
                </h3>
                <p className="text-sm sm:text-base mt-2">
                  In combination with abiraterone and prednisone or prednisolone
                  (abi/pred) for the treatment of adult patients with
                  deleterious or suspected deleterious <em>BRCA</em>-mutated (
                  <em>BRCA</em>m) metastatic castration-resistant prostate
                  cancer (mCRPC). Select patients for therapy based on an
                  FDA-approved companion diagnostic for LYNPARZA.
                </p>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3">
            <h2 class="text-lg sm:text-xl text-[#004D74] font-bold" id="header-isi">
              IMPORTANT SAFETY INFORMATION
            </h2>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                CONTRAINDICATIONS
              </h3>
              <p>
                There are no contraindications{" "}
                {" "} <span class="whitespace-nowrap">for LYNPARZA.</span> {" "}
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                WARNINGS AND PRECAUTIONS
              </h3>
              <ul class="list-disc ml-5">
                <li>
                  {" "} <span class="font-semibold">
                    Myelodysplastic Syndrome/Acute Myeloid Leukemia (MDS/AML):{" "}
                  </span> {" "}
                  Occurred in approximately{" "}
                  {" "} <span class="whitespace-nowrap">1.2%</span> {" "} of patients with
                  various
                  <em>BRCA</em>m, g<em>BRCA</em>m, HRR gene-mutated or
                  HRD-positive cancers who received LYNPARZA as a single agent
                  or as part of a combination regimen, consistent with the
                  approved indications, and the majority of events had a fatal
                  outcome. The median duration of therapy in patients who
                  developed MDS/AML was approximately{" "}
                  {" "} <span class="whitespace-nowrap">2 years</span> {" "}
                  {" "} <span class="whitespace-nowrap">(range: &lt;6 months</span> {" "} to
                  {" "} <span class="whitespace-nowrap">&gt;4 years).</span> {" "} All of
                  these patients had previous chemotherapy with platinum agents
                  and/or other{" "}
                  {" "} <span class="whitespace-nowrap">DNA-damaging</span> {" "} agents,
                  including radiotherapy.
                </li>
                <li>
                  In SOLO-1, patients with newly diagnosed advanced{" "}
                  <em>BRCA</em>m ovarian cancer, the incidence of MDS/AML was
                  1.9% (5/260) in patients who received LYNPARZA and 0.8%
                  (1/130) in patients who received placebo based on an updated
                  analysis. In PAOLA-1, of patients with newly diagnosed
                  advanced ovarian cancer with HRD-positive status, the
                  incidence of MDS/AML was 1.6% (4/255) in patients who received
                  LYNPARZA and 2.3% (3/131) in the control arm.
                </li>
                <li>
                  In SOLO-2, patients with <em>BRCA</em>m platinum-sensitive
                  relapsed ovarian cancer, the incidence of MDS/AML was 8%
                  (15/195) in patients who received LYNPARZA and 4% (4/99) in
                  patients who received placebo. The duration of LYNPARZA
                  treatment prior to the diagnosis of MDS/AML ranged from 0.6
                  years to 4.5 years.
                </li>
                <li>
                  Do not start LYNPARZA until patients have recovered from
                  hematological toxicity caused by previous chemotherapy{" "}
                  {" "} <span class="whitespace-nowrap">(≤Grade 1).</span> {" "} Monitor
                  complete blood count for cytopenia at baseline and monthly
                  thereafter for clinically significant changes during
                  treatment. For prolonged hematological toxicities, interrupt
                  LYNPARZA and monitor blood count weekly
                  {" "} <span class="whitespace-nowrap">until recovery.</span> {" "}
                </li>
                <li>
                  If the levels have not recovered to Grade 1 or less after
                  {" "} <span class="whitespace-nowrap">4 weeks,</span> {" "} refer the
                  patient to a hematologist for further investigations,
                  including bone marrow analysis and blood sample for
                  cytogenetics. Discontinue LYNPARZA if MDS/AML{" "}
                  {" "} <span class="whitespace-nowrap">is confirmed.</span> {" "}
                </li>
                <li>
                  {" "} <span class="font-semibold">Pneumonitis:</span> {" "} Occurred in
                  {" "} <span class="whitespace-nowrap">0.8%</span> {" "} of patients
                  exposed to LYNPARZA monotherapy, and some cases were fatal. If
                  patients present with new or worsening respiratory symptoms
                  such as dyspnea, cough, and fever, or a radiological
                  abnormality occurs, interrupt LYNPARZA treatment and initiate
                  prompt investigation. Discontinue LYNPARZA if pneumonitis is
                  confirmed and treat
                  {" "} <span class="whitespace-nowrap">patient appropriately.</span> {" "}
                </li>
                <li>
                  {" "} <span class="font-semibold">
                    Venous Thromboembolism (VTE):
                  </span> {" "}{" "}
                  Including severe or fatal pulmonary embolism (PE) occurred in
                  patients treated with LYNPARZA. In the combined data of two
                  randomized,
                  {" "} <span class="whitespace-nowrap">placebo-controlled</span> {" "}{" "}
                  clinical studies (PROfound and PROpel) in patients with
                  metastatic castration-resistant prostate cancer (N=1180), VTE
                  occurred in 8% of patients who received LYNPARZA, including
                  pulmonary embolism in 6%. In the control arms, VTE occurred in
                  2.5%, including pulmonary embolism in 1.5%. Monitor patients
                  for signs and symptoms of venous thrombosis and pulmonary
                  embolism, and treat as medically appropriate, which may
                  include long-term anticoagulation as clinically indicated.
                </li>
                <li>
                  {" "} <span class="font-semibold">Embryo-Fetal Toxicity:</span> {" "}{" "}
                  Based on its mechanism of action and findings in animals,
                  LYNPARZA can cause fetal harm. Verify pregnancy status in
                  females of reproductive potential prior to initiating
                  treatment.
                </li>
                <li>
                  {" "} <span class="italic">Females</span> {" "} Advise females of
                  reproductive potential of the potential risk to a fetus and to
                  use effective contraception during treatment and for
                  {" "} <span class="whitespace-nowrap">6 months</span> {" "} following the
                  {" "} <span class="whitespace-nowrap">last dose.</span> {" "}
                </li>
                <li>
                  {" "} <span class="italic">Males</span> {" "} Advise male patients with
                  female partners of reproductive potential or who are pregnant
                  to use effective contraception during treatment and for 3
                  months following the last dose of LYNPARZA and to not donate
                  sperm during this time.
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—First-Line Maintenance <em>BRCA</em>m Advanced
                Ovarian Cancer
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥10% of patients
                who received LYNPARZA in the
                {" "} <span class="font-semibold">
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "} maintenance
                  setting
                </span> {" "}
                {" "} <span class="whitespace-nowrap">for</span> {" "}
                {" "} <span class="font-semibold">SOLO-1</span> {" "} were: nausea (77%),
                fatigue (67%), abdominal pain (45%), vomiting (40%), anemia
                (38%), diarrhea (37%), constipation (28%),
                {" "} <span class="whitespace-nowrap">upper respiratory tract</span> {" "}
                {" "} <span class="whitespace-nowrap">infection</span> {" "}/influenza/
                {" "} <span class="whitespace-nowrap">nasopharyngitis</span> {" "}/
                {" "} <span class="whitespace-nowrap">bronchitis</span> {" "} (28%),
                dysgeusia (26%), decreased appetite (20%), dizziness (20%),
                neutropenia (17%), dyspepsia (17%), dyspnea (15%), leukopenia
                (13%), urinary tract infection (13%), thrombocytopenia (11%),
                {" "} <span class="whitespace-nowrap">and stomatitis (11%).</span> {" "}
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4){" "}
                {" "} <span class="whitespace-nowrap">in ≥25%</span> {" "} of patients who
                received LYNPARZA in the
                {" "} <span class="font-semibold">
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "} maintenance
                  setting
                </span> {" "}
                for {" "} <span class="font-semibold">SOLO-1</span> {" "} were: decrease in
                hemoglobin (87%), increase in mean corpuscular volume (87%),
                decrease in leukocytes (70%), decrease in lymphocytes (67%),
                decrease in absolute neutrophil count (51%), decrease in
                platelets (35%), and increase in
                {" "} <span class="whitespace-nowrap">serum creatinine (34%).</span> {" "}
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—First-Line Maintenance Advanced Ovarian Cancer
                in Combination with Bevacizumab
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥10% of patients
                treated with LYNPARZA/bevacizumab and at a ≥5% frequency
                compared to{" "}
                {" "} <span class="whitespace-nowrap">placebo/bevacizumab</span> {" "} in
                the
                {" "} <span class="font-semibold">
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "} maintenance
                  setting
                </span> {" "}
                for {" "} <span class="font-semibold">PAOLA-1</span> {" "} were: nausea
                (53%), fatigue (including asthenia) (53%), anemia (41%),
                lymphopenia (24%), vomiting (22%), and leukopenia (18%). In
                addition, the most common adverse reactions (≥10%) for patients
                receiving LYNPARZA/bevacizumab irrespective of the frequency
                compared with the placebo/bevacizumab arm were: diarrhea (18%),
                neutropenia (18%), urinary tract infection (15%), and{" "}
                {" "} <span class="whitespace-nowrap">headache (14%).</span> {" "}
              </p>
              <p>
                In addition, venous thromboembolism occurred more commonly in
                patients receiving LYNPARZA/bevacizumab (5%) than in those
                receiving placebo/bevacizumab (1.9%).
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4){" "}
                {" "} <span class="whitespace-nowrap">in ≥25%</span> {" "} of patients for
                LYNPARZA in combination with bevacizumab in the
                {" "} <span class="font-semibold">
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "} maintenance
                  setting
                </span> {" "}
                for {" "} <span class="font-semibold">PAOLA-1</span> {" "} were: decrease in
                hemoglobin (79%), decrease in lymphocytes (63%), increase in
                serum creatinine (61%), decrease in leukocytes (59%), decrease
                in absolute neutrophil count (35%), and decrease
                {" "} <span class="whitespace-nowrap">in platelets (35%).</span> {" "}
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—Maintenance g<em>BRCA</em>m Recurrent
                {" "} <span class="whitespace-nowrap">Ovarian Cancer</span> {" "}
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥20% of patients
                who received LYNPARZA in the
                {" "} <span class="font-semibold">maintenance setting</span> {" "} for
                {" "} <span class="font-semibold">SOLO-2</span> {" "} were: nausea (76%),
                fatigue (including asthenia) (66%), anemia (44%), vomiting
                (37%), nasopharyngitis/upper respiratory tract infection
                (URI)/influenza (36%), diarrhea (33%), arthralgia/myalgia (30%),
                dysgeusia (27%), headache (26%), decreased appetite (22%),
                {" "} <span class="whitespace-nowrap">and stomatitis (20%).</span> {" "}
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4){" "}
                {" "} <span class="whitespace-nowrap">in ≥25%</span> {" "} of patients who
                received LYNPARZA in the{" "}
                {" "} <span class="font-semibold">maintenance setting</span> {" "} for
                {" "} <span class="font-semibold">SOLO-2</span> {" "} were: increase in mean
                corpuscular volume (89%), decrease in hemoglobin (83%), decrease
                in leukocytes (69%), decrease in lymphocytes (67%), decrease in
                absolute neutrophil count (51%), increase in serum creatinine
                {" "} <span class="whitespace-nowrap">
                  (44%), and decrease in platelets (42%).
                </span> {" "}
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—Adjuvant Treatment of g<em>BRCA</em>m,
                {" "} <span class="whitespace-nowrap">HER2-Negative,</span> {" "} High-Risk
                Early
                {" "} <span class="whitespace-nowrap">Breast Cancer</span> {" "}
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥10% of patients
                who received LYNPARZA in the
                {" "} <span class="font-semibold">adjuvant setting</span> {" "} for
                {" "} <span class="font-semibold">OlympiA</span> {" "} were: nausea (57%),
                fatigue (including asthenia) (42%), anemia (24%), vomiting
                (23%), headache (20%), diarrhea (18%), leukopenia (17%),
                neutropenia (16%), decreased appetite (13%), dysgeusia (12%),
                dizziness (11%),
                {" "} <span class="whitespace-nowrap">and stomatitis (10%).</span> {" "}
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4) in ≥25% of
                patients who received LYNPARZA in the
                {" "} <span class="font-semibold">adjuvant setting</span> {" "} for
                {" "} <span class="font-semibold">OlympiA</span> {" "} were: decrease in
                lymphocytes (77%), increase in mean corpuscular volume (67%),
                decrease in hemoglobin (65%), decrease in leukocytes (64%), and
                decrease in absolute neutrophil count (39%).
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—g<em>BRCA</em>m,
                {" "} <span class="whitespace-nowrap">HER2-Negative</span> {" "} Metastatic
                Breast Cancer
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥20% of patients
                who received LYNPARZA in the
                {" "} <span class="font-semibold">metastatic setting</span> {" "} for
                {" "} <span class="font-semibold">OlympiAD</span> {" "} were: nausea (58%),
                anemia (40%), fatigue (including asthenia) (37%), vomiting
                (30%), neutropenia (27%), respiratory tract infection (27%),
                leukopenia (25%), diarrhea (21%), and{" "}
                {" "} <span class="whitespace-nowrap">headache (20%).</span> {" "}
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4) in ≥25% of
                patients who received LYNPARZA in the
                {" "} <span class="font-semibold">metastatic setting</span> {" "} for
                {" "} <span class="font-semibold">OlympiAD</span> {" "} were: decrease in
                hemoglobin (82%), decrease in lymphocytes (73%), decrease in
                leukocytes (71%), increase in mean corpuscular volume (71%),
                decrease in absolute neutrophil count (46%), and decrease
                {" "} <span class="whitespace-nowrap">in platelets (33%).</span> {" "}
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—First-Line Maintenance g<em>BRCA</em>m
                Metastatic
                {" "} <span class="whitespace-nowrap">Pancreatic Adenocarcinoma</span> {" "}
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥10% of patients
                who received LYNPARZA in the
                {" "} <span class="font-semibold">
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "} maintenance
                  setting
                </span> {" "}
                for {" "} <span class="font-semibold">POLO</span> {" "} were: fatigue (60%),
                nausea (45%), abdominal pain (34%), diarrhea (29%), anemia
                (27%), decreased appetite (25%), constipation (23%), vomiting
                (20%), back pain (19%), arthralgia (15%), rash (15%),
                thrombocytopenia (14%), dyspnea (13%), neutropenia (12%),
                nasopharyngitis (12%), dysgeusia (11%),{" "}
                {" "} <span class="whitespace-nowrap">and stomatitis (10%).</span> {" "}
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4) in ≥25% of
                patients who received LYNPARZA in the
                {" "} <span class="font-semibold">
                  {" "} <span class="whitespace-nowrap">first-line</span> {" "} maintenance
                  setting
                </span> {" "}
                for {" "} <span class="font-semibold">POLO</span> {" "} were: increase in
                serum creatinine (99%), decrease in hemoglobin (86%), increase
                in mean corpuscular volume (71%), decrease in lymphocytes (61%),
                decrease in platelets (56%), decrease in leukocytes (50%), and
                decrease in absolute
                {" "} <span class="whitespace-nowrap">neutrophil count (25%).</span> {" "}
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—HRR Gene-mutated Metastatic
                Castration-Resistant Prostate Cancer
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions{" "}
                {" "} <span class="whitespace-nowrap">(Grades 1-4)</span> {" "} in
                {" "} <span class="whitespace-nowrap">≥10% of patients</span> {" "} who
                received LYNPARZA for
                {" "} <span class="font-semibold">PROfound</span> {" "} were: anemia (46%),
                fatigue
                {" "} <span class="whitespace-nowrap">(including asthenia)</span> {" "}{" "}
                (41%), nausea (41%), decreased appetite (30%), diarrhea (21%),
                vomiting (18%), thrombocytopenia (12%), cough (11%), and dyspnea
                (10%).
              </p>
              <p>
                Most common laboratory abnormalities{" "}
                {" "} <span class="whitespace-nowrap">(Grades 1-4)</span> {" "} in
                {" "} <span class="whitespace-nowrap">≥25% of patients</span> {" "} who
                received LYNPARZA for
                {" "} <span class="font-semibold">PROfound</span> {" "} were: decrease in
                hemoglobin (98%), decrease in lymphocytes (62%), decrease in
                leukocytes (53%), and decrease in absolute neutrophil count
                (34%).
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                ADVERSE REACTIONS—Metastatic Castration-Resistant Prostate
                Cancer in Combination with Abiraterone and Prednisone or
                Prednisolone
              </h3>
              <p className="text-sm sm:text-base mt-2">
                Most common adverse reactions (Grades 1-4) in ≥10% of patients
                who received LYNPARZA/abiraterone with a difference of ≥5%
                compared to placebo for{" "}
                {" "} <span class="font-semibold">PROpel</span> {" "} were: anemia (48%),
                fatigue (including asthenia) (38%), nausea (30%), diarrhea
                (19%), decreased appetite (16%), lymphopenia (14%), dizziness
                (14%), and abdominal pain (13%).
              </p>
              <p>
                Most common laboratory abnormalities (Grades 1-4) in ≥20% of
                patients who received LYNPARZA/abiraterone for{" "}
                {" "} <span class="font-semibold">PROpel</span> {" "} were: decrease in
                hemoglobin (97%), decrease in lymphocytes (70%), decrease in
                platelets (23%), and decrease in absolute neutrophil count
                (23%).
              </p>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                DRUG INTERACTIONS
              </h3>
              <ul class="list-disc ml-5">
                <li>
                  {" "} <span class="font-semibold">Anticancer Agents:</span> {" "} Clinical
                  studies of LYNPARZA with other myelosuppressive anticancer
                  agents, including
                  {" "} <span class="whitespace-nowrap">
                    DNA-damaging agents,
                  </span> {" "}{" "}
                  indicate a potentiation and prolongation of{" "}
                  {" "} <span class="whitespace-nowrap">
                    myelosuppressive toxicity.
                  </span> {" "}
                </li>
                <li>
                  {" "} <span class="font-semibold">CYP3A Inhibitors:</span> {" "} Avoid
                  coadministration of strong or moderate
                  {" "} <span class="whitespace-nowrap">CYP3A inhibitors</span> {" "} when
                  using LYNPARZA. If a strong or moderate
                  {" "} <span class="whitespace-nowrap">CYP3A inhibitor</span> {" "} must be
                  coadministered, reduce the dose of LYNPARZA. Advise patients
                  to avoid grapefruit, grapefruit juice, Seville oranges, and
                  Seville orange juice during LYNPARZA treatment.
                </li>
                <li>
                  {" "} <span class="font-semibold">CYP3A Inducers:</span> {" "} Avoid
                  coadministration of strong or moderate CYP3A inducers when{" "}
                  {" "} <span class="whitespace-nowrap">using LYNPARZA.</span> {" "}
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-lg sm:text-xl  text-[#004D74] font-semibold">
                USE IN SPECIFIC POPULATIONS
              </h3>
              <ul class="list-disc ml-5">
                <li>
                  {" "} <span class="font-semibold">Lactation:</span> {" "} No data are
                  available regarding the presence of olaparib in human milk,
                  its effects on the breastfed infant or on milk production.
                  Because of the potential for serious adverse reactions in the
                  breastfed infant, advise a lactating woman not to breastfeed
                  during treatment with LYNPARZA and for 1 month after receiving
                  the
                  {" "} <span class="whitespace-nowrap">final dose.</span> {" "}
                </li>
                <li>
                  {" "} <span class="font-semibold">Pediatric Use:</span> {" "} The safety
                  and efficacy of LYNPARZA have not been established in{" "}
                  {" "} <span class="whitespace-nowrap">pediatric patients.</span> {" "}
                </li>
                <li>
                  {" "} <span class="font-semibold">Hepatic Impairment:</span> {" "} No
                  adjustment to the starting dose is required in patients with
                  mild or moderate hepatic impairment
                  {" "} <span class="whitespace-nowrap">
                    (Child-Pugh classification A and B).
                  </span> {" "}{" "}
                  There are no data in patients with severe hepatic impairment
                  {" "} <span class="whitespace-nowrap">
                    (Child-Pugh classification C).
                  </span> {" "}
                </li>
                <li>
                  {" "} <span class="font-semibold">Renal Impairment:</span> {" "} No dosage
                  modification is recommended in patients with mild renal
                  impairment
                  {" "} <span class="whitespace-nowrap">(CLcr 51-80 mL/min</span> {" "}{" "}
                  estimated by
                  {" "} <span class="whitespace-nowrap">Cockcroft-Gault).</span> {" "} In
                  patients with moderate renal impairment
                  {" "} <span class="whitespace-nowrap">
                    (CLcr 31-50 mL/min),
                  </span> {" "}{" "}
                  reduce the dose of LYNPARZA to
                  {" "} <span class="whitespace-nowrap">200 mg</span> {" "} twice daily.
                  There are no data in patients with severe renal impairment or{" "}
                  {" "} <span class="whitespace-nowrap">end-stage</span> {" "} renal disease
                  {" "} <span class="whitespace-nowrap">(CLcr ≤30 mL/min).</span> {" "}
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <p className="font-bold text-base">
                Please see complete
                <a
                  href="http://www.azpicentral.com/pi.html?product=lynparza_tb"
                  target="_blank"
                  aria-label="Prescribing Information opens in new tab"
                  class="text-blue-600 underline"
                >
                 {" "} Prescribing Information
                </a>
                , including {" "}
                <a
                  href="http://www.azpicentral.com/pi.html?product=lynparza_tb&amp;medguide=y"
                  target="_blank"
                  aria-label="Medication Guide opens in new tab"
                  class="text-blue-600 underline"
                >
                  {" "} <span class="whitespace-nowrap"> Medication Guide</span> {" "}
                </a>
                .
              </p>
              <p className="mt-3">
                <i>You may</i>
                <u>
                  <a
                    href="https://us-aereporting.astrazeneca.com/adverse-events.html"
                    target="_blank"
                    class="text-blue-600 underline"
                  >
                    <i>{" "}report side effects related to AstraZeneca products</i>
                  </a>
                </u>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    // </Modal>
  );
};

export default ISIModal;
