import instance from "../redux/actions/AxiosInstance/authenticated";
import UnAutenticatedInstance from "../redux/actions/AxiosInstance/unAuthenticated";


export const handleRequest = async (input, assistant = "", userId, brand) => {
  try {
    const resp = await instance.post("/video/response", {
      assistant: assistant,
      userInput: input,
      userId: userId,
      userBrand: brand,
    });

    return { success: true, data: resp.data };
  } catch (error) {
    return { success: false };
  }
};

export const checkPersonalizedQuestions = async (userId, userBrand) => {
  try {
    const resp = await instance.post("/conversation/personalized", {
      userId: userId,
      userBrand: userBrand,
    });
    return { success: true, data: resp.data };
  } catch (error) {
    return { success: false };
  }
};

export const updateUser = async ({
  inviteAccepted,
  prefix,
  firstName,
  lastName,
  termsOfUseAccepted,
}) => {
  try {
    const resp = await instance.put("/user/update", {
      inviteAccepted: inviteAccepted,
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      termsOfUseAccepted: termsOfUseAccepted,
    });
    return { success: true, data: resp.data, message: resp.data.message };
  } catch (error) {
    console.error(error);
    throw new Error(error.response.data.message);
  }
};

// this function is deprecated
export const verifySecretCode = async (secretCode) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/user/verify-secret`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ secretCode: secretCode }),
      }
    );
    const data = await resp.json();
    if (resp.status === 200) {
      return { success: true, message: data.message, data: data.data };
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getScript = async (scriptType, brand = null) => {
  try {
    const resp = await instance.post("/scripts/", {
      script: scriptType,
      brand: brand,
    });
    return { success: true, data: resp.data.data };
  } catch (error) {
    return { success: false };
  }
};
export const getUserInfo = async () => {
  try {
    const resp = await instance.get("/user/user-info");
    return { success: true, data: resp.data.user };
  } catch (error) {
    throw new Error("Failed to fetch user info"); 
  }
};

export const shareToHCPs = async (brandName,invitedBy) => {
  try {
    const resp = await instance.post("/user/share", {
     
      brandName: brandName,
      invitedBy: invitedBy,
    });
    return { success: true, data: resp.data.data };
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export const verifyInvitation = async (brand, token) => {
  try {
    const resp = await UnAutenticatedInstance.post("/user/verify-invitation", {
      brand: brand,
      token: token,
    });
    return { success: true, data: resp.data.data, message: resp.data.message };
  } catch (error) {
   return { success: false, message: error.response.data.message };
  }
};

export const hcpSelfRegistration = async (hcpDetails, token) => {
  try {
    const resp = await UnAutenticatedInstance.post("/user/hcp-self-registration", {
      hcpDetails: hcpDetails,
      token: token,
    });
    if (resp.data.success) {
      return { success: true, message: resp.data.message };
    } else {
      return { success: false, message: resp.data.message };
    }
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
