import React from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { Navbar } from "./Navbar";
import { getCookie, clearHCPCookies, setCookie } from "../utils/cookie";
import TermsOfUseModal from "./TermsOfUseModal";
import ISIModal from "./ISIModal";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  const [showModal, setShowModal] = React.useState(true);
  const [showISIModal, setShowISIModal] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleAccept = async () => {
    try {
      const decodedToken = decodeToken(inviteId);
      if (decodedToken && decodedToken.exp) {
        const expirationTimestamp = decodedToken.exp;
        const date = new Date(expirationTimestamp * 1000);
        const expires = date.toUTCString();
        setCookie("accessToken", inviteId, expires);
      }
      setShowModal(false);
    } catch (error) {
      navigate("/signin");
    }
  };

  const ISIBanner = (
    <div
      className={`fixed bottom-0 w-full bg-white text-left z-50 border-t border-gray-200 transition-all duration-500 ease-in-out ${
        isExpanded ? 'h-[96%] overflow-y-auto' : 'h-36'
      }`}
    >
      <div className="w-full lg:w-3/4 mx-auto px-4 py-2">
        {/* Button for Toggling */}
        <div className="flex justify-between items-start sticky top-0 bg-white z-10">
          <div className="w-5/6">
          <p className="text-md italic font-bold text-[#004D74]">
              See Important Safety Information, including Warnings and Precautions for MDS/AML, pneumonitis, venous thromboembolism, and embryo-fetal toxicity below.
            </p>
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center text-[#004D74] font-semibold focus:outline-none"
            aria-expanded={isExpanded}
          >
            {isExpanded ? (
              <>
                Read Less
                <MdKeyboardArrowDown className="w-5 h-5 ml-1" />
              </>
            ) : (
              <>
                Read More
                <MdKeyboardArrowUp className="w-5 h-5 ml-1" />
              </>
            )}
          </button>
        </div>

        {!isExpanded && (
          <div className="w-full mx-auto">
            <div className="flex justify-between">

              {/* Left section for indications */}
              <div className="w-1/2 pr-4 mt-2">

                <h2 className="text-lg sm:text-xl text-[#004D74] font-bold">INDICATIONS</h2>
                <p className="text-xs sm:text-sm">
                  LYNPARZA is a poly (ADP-ribose) polymerase (PARP)
                  <span className="whitespace-nowrap">inhibitor indicated:</span>
                </p>
                <p className="text-xs sm:text-sm font-bold text-[#004D74] mt-1">
                  First-Line Maintenance BRCAm Advanced Ovarian Cancer
                </p>
              </div>

              {/* Right section for safety information */}
              <div className="w-1/2 pl-4 border-l border-gray-200 mt-2">
                <h2 className="text-lg sm:text-xl text-[#004D74] font-bold" id="header-isi">
                  IMPORTANT SAFETY INFORMATION
                </h2>
                <div>
                  <h3 className="text-base sm:text-lg md:text-xl text-[#004D74] font-semibold">
                    CONTRAINDICATIONS
                  </h3>
                  <p className="text-xs sm:text-sm">
                    There are no contraindications{" "}
                    <span className="whitespace-nowrap">for LYNPARZA.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`transition-all duration-500 ease-in-out ${
            isExpanded ? 'max-h-full' : 'max-h-0'
          } overflow-hidden`}
        >
          {<ISIModal />}
        </div>
      </div>
    </div>
  );


  // Handle inviteId case
  if (inviteId) {
    return (
      <>
        {showModal ? (
          <TermsOfUseModal onAccept={handleAccept} />
        ) : (
          <>
            {showISIModal && <ISIModal setShowISIModal={setShowISIModal} />}
            <div className="fixed top-0 w-full bg-yellow-100 py-1.5 text-center flex items-center justify-center z-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p className="text-sm cursor-pointer">
                This information is meant for US healthcare professionals only
              </p>
            </div>
            <div className="pt-8">
              <Navbar />
            </div>
            <Outlet />
            <div className="fixed bottom-0 w-full bg-yellow-100 py-2 text-center z-50">
              {ISIBanner}
            </div>
          </>
        )}
      </>
    );
  }

  // Handle cases where inviteId is not present
  if (!inviteId) {
    if (window.location.pathname.includes("/pharma")) {
      return <Outlet />;
    }

    const auth = getCookie("accessToken");

    if (!auth) {
      return <Navigate to="/signin" />;
    }

    const isMyTokenExpired = isExpired(auth);
    if (isMyTokenExpired) {
      clearHCPCookies();
      return <Navigate to="/signin" />;
    }

    return (
      <>
        {showISIModal && <ISIModal setShowISIModal={setShowISIModal} />}
        <div className="fixed top-0 w-full bg-yellow-100 py-1.5 text-center flex items-center justify-center z-50">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-1 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="text-sm cursor-pointer">
            This information is meant for US healthcare professionals only
          </p>
        </div>
        <div className="pt-8">
          <Navbar />
        </div>
        <Outlet />
        <div className="fixed bottom-0 w-full bg-yellow-100 py-2 text-center z-50">
          {ISIBanner}
        </div>
      </>
    );
  }

  return <Navigate to="/signin" />;
};

export default PrivateRoutes;
