import React, { useContext, useEffect, useRef, useState } from "react";
import AvatarContext from "../../../components/AvatarContext";
import annyang from "annyang";

const Video = () => {
  const silentRef = useRef(null);
  const browseRef = useRef(null);
  const talkingRef = useRef(null);
  const followupRef = useRef(null);

  const {
    setGreetingVideo,
    response,
    videoSilent,
    setVideoSilent,
    listening,
    setListening,
    setQuestion,
    requestLoader,
    setRequestLoader,
    setShowFollowupQuestion,
    playVideo,
  } = useContext(AvatarContext);

  const [followup, setFollowup] = useState(false);
  const [browse, setBrowse] = useState(false);
  const [showPauseButton, setShowPauseButton] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setGreetingVideo("");
    setShowStartButton(false);
    if (listening) {
      annyang.abort();
    }
    if (response.video) {
      talkingRef.current.src = response.video;
      const checkVideoLoaded = () => {
        try {
          if (talkingRef?.current?.readyState === 4) {
            setVideoSilent(false);
            playVideo(true);
          } else {
            setTimeout(checkVideoLoaded, 100);
          }
        } catch (error) {
          throw error;
        }
      };

      checkVideoLoaded();
    }
  }, [response.video]);

  function getRandomVideoOrNull() {
    const videos = [
      "https://res.cloudinary.com/ds80j9gac/video/upload/v1705300018/Browse-Screen-1.mp4",
      "https://res.cloudinary.com/ds80j9gac/video/upload/v1705300391/Browse-Screen-2.mp4",
      "https://res.cloudinary.com/ds80j9gac/video/upload/v1705300600/Browse-Screen-3.mp4",
    ];

    // Randomly decide whether to return a video or null
    const returnVideo = Math.random() < 0.5;

    if (returnVideo) {
      const randomIndex = Math.floor(Math.random() * videos.length);
      return videos[randomIndex];
    } else {
      return null;
    }
  }

  const startAnnyang = () => {
    if (annyang) {
      setListening(true);

      const commands = {
        "*text": (text) => {
          setQuestion(text);
          setRequestLoader(true);
        },
      };

      annyang.addCommands(commands);
      annyang.start();
    }
  };
  const onExplanationVideoEnded = () => {
    if (response.followup_video) {
      if (browseRef.current.src) {
        const checkVideoLoaded = () => {
          try {
            if (browseRef?.current?.readyState === 4) {
              browseRef.current.play();
              setBrowse(true);
            } else {
              setTimeout(checkVideoLoaded, 100);
            }
          } catch (error) {
            throw error;
          }
        };
        checkVideoLoaded();
        return;
      } else {
        onBrowseScreenVideoEnded();
      }
    } else {
      onFollowupVideoEnded();
    }
  };

  const onExplanationVIdeoPlaying = () => {
    if (response.followup_video) {
      let browseVideo = getRandomVideoOrNull();
      if (browseVideo) {
        browseRef.current.src = browseVideo;
      } else {
        followupRef.current.src = response.followup_video;
      }
    } else {
      silentRef.current.src = "/videos/silence_conv.mp4";
    }
  };

  const onBrowseScreenVideoEnded = () => {
    if (response.followup_video) {
      const checkVideoLoaded = () => {
        try {
          if (followupRef?.current?.readyState === 4) {
            followupRef.current.muted = false;
            followupRef.current.play();
            setBrowse(false);
            setFollowup(true);
          } else {
            setTimeout(checkVideoLoaded, 100);
          }
        } catch (error) {
          throw error;
        }
      };

      checkVideoLoaded();
    }
  };

  useEffect(() => {
    if (!videoSilent || browse || followup) {
      silentRef.current.src = "/videos/silence_conv.mp4";
      talkingRef.current.pause();
      followupRef.current.pause();
      browseRef.current.pause();

      onFollowupVideoEnded();
    }
  }, [requestLoader]);

  const onBrowseScreenVideoPlaying = () => {
    if (response.followup_video) {
      followupRef.current.src = response.followup_video;
    } else {
      silentRef.current.src = "/videos/silence_conv.mp4";
    }
  };
  const onFollowupVideoEnded = () => {
    const checkVideoLoaded = () => {
      try {
        if (silentRef?.current?.readyState === 4) {
          setBrowse(false);
          setVideoSilent(true);
          setFollowup(false);
        } else {
          setTimeout(checkVideoLoaded, 100);
        }
      } catch (error) {
        throw error;
      }
      if (listening) {
        startAnnyang();
      }
    };

    checkVideoLoaded();
  };
  const onFollowupVideoPlaying = () => {
    silentRef.current.src = "/videos/silence_conv.mp4";
    setShowFollowupQuestion(true);
  };

  const togglePause = () => {
    setShowStartButton(true);
    playVideo(false);
    if (browse) {
      browseRef.current.pause();
      return;
    }
    if (followup) {
      followupRef.current.pause();
      return;
    }
    if (!videoSilent) {
      talkingRef.current.pause();
      return;
    }
  };
  const toggleStart = () => {
    setShowButton(true);
    setShowStartButton(false);
    playVideo(true);
    if (browse) {
      browseRef.current.play();
      return;
    }
    if (followup) {
      followupRef.current.play();
      return;
    }
    if (!videoSilent) {
      talkingRef.current.play();
      return;
    }
  };

  useEffect(() => {
    if (!videoSilent || browse || followup) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [videoSilent, browse, followup]);
  return (
   <div
    className="md:w-[30%] mx-auto h-fit my-auto relative"
    onMouseEnter={() =>
      !showStartButton && showButton ? setShowPauseButton(true) : ""
    }
    onMouseLeave={() =>
      !showStartButton && showButton ? setShowPauseButton(false) : ""
    }
  >
     <p className="text-sm text-gray-800 font-semibold absolute top-32 z-30">* Not an actual person</p>
      <video
        ref={silentRef}
        autoPlay
        loop
        muted
        className={`object-cover`}
        style={
          videoSilent && !followup && !browse
            ? { display: "block", position: "relative" }
            : { display: "none" }
        }
        onCanPlayThrough={(e) => {
          // setLoading(false);
        }}
        onClick={(e) => {
          e.preventDefault();
          e.target.muted = false;
        }}
      />
      <video
        ref={talkingRef}
        autoPlay
        className={`object-cover animate-fade-in-1s`}
        style={
          !videoSilent && !followup && !browse
            ? { display: "block", position: "relative" }
            : { display: "none" }
        }
        onEnded={(e) => {
          onExplanationVideoEnded();
        }}
        onCanPlayThrough={() => {
          onExplanationVIdeoPlaying();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.target.muted = false;
        }}
        onError={(e) => {}}
      />
      <video
        ref={followupRef}
        // autoPlay
        className={`object-cover`}
        style={
          followup && !browse
            ? { display: "block", position: "relative" }
            : { display: "none" }
        }
        onCanPlayThrough={(e) => {
          onFollowupVideoPlaying();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.target.muted = false;
        }}
        onEnded={() => {
          onFollowupVideoEnded();
        }}
      />
      <video
        ref={browseRef}
        // autoPlay
        className={`object-cover`}
        style={
          browse
            ? { display: "block", position: "relative" }
            : { display: "none" }
        }
        onClick={(e) => {
          e.preventDefault();
          e.target.muted = false;
        }}
        onCanPlayThrough={() => {
          onBrowseScreenVideoPlaying();
        }}
        onEnded={() => {
          onBrowseScreenVideoEnded();
        }}
      />
      {!videoSilent && showStartButton && (
      <button
        onClick={() => toggleStart()}
        className="absolute left-1/2 transform -translate-x-1/2 bottom-4 px-2 py-1 cursor-pointer border-none bg-white bg-opacity-70 rounded text-sm"
      >
        Click to start
      </button>
    )}
    {!videoSilent && showPauseButton && !showStartButton && (
      <button
        onClick={() => togglePause()}
        className="absolute left-1/2 transform -translate-x-1/2 bottom-4 px-2 py-1 cursor-pointer border-none bg-white bg-opacity-70 rounded text-sm"
      >
        Click to stop
      </button>
    )}
    </div>
  );
};

const videoButtonStyle = {
  position: "absolute",
  left: "19%",
  transform: "translateX(-50%)",
  bottom: "20%",
  padding: "5px 10px",
  cursor: "pointer",
  border: "none",
  background: "rgba(255, 255, 255, 0.7)",
  borderRadius: "5px",
  fontSize: "small",
};

export default Video;
